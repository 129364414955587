import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWork } from '../services/apiService';
import '../css/OurWork.css';

const OurWork = () => {
  const { t, i18n } = useTranslation();
  const [activeImage, setActiveImage] = useState(null);
  const [work, setWork] = useState([]);

  useEffect(() => {
    fetchWork(i18n.language)
        .then(data => setWork(data))
        .catch(error => console.error(error));
  }, [i18n.language]);

  const handleImageClick = (index) => {
    setActiveImage(activeImage === index ? null : index);
  };

  return (
      <section id="our-work" className="our-work">
        <h2 className="title">{t('OurWork.title')}</h2>
        {work?.length > 0 ? (
            <div className="work-gallery">
              {work.map((item, index) => (
                  <div
                      key={index}
                      className={`work-card ${activeImage === index ? 'flipped' : ''}`}
                      onClick={() => handleImageClick(index)}
                  >
                    <div className="work-card-inner">
                      <div className="work-card-front">
                        <img src={`data:image/png;base64,${item.logo}`} alt={item.title} />
                        <div className="work-card-title">{item.title}</div>
                      </div>
                      <div className="work-card-back">
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
        ) : (
            <h3 className="No-works">{t('no-works')}</h3>
        )}
      </section>
  );
};

export default OurWork;
