import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import brainIcon from '../assets/brain.png';
import codingIcon from '../assets/coding.png';
import designIcon from '../assets/design.png';
import pieChartIcon from '../assets/pie-chart.png';
import Bulb from '../assets/Bulb.png';
import nextArrow from '../assets/Arrow-next.png';
import '../css/OurProcess.css';

const OurProcess = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  const steps = [
    { title: t('OurProcess.title1'), description: t('OurProcess.description1') },
    { title: t('OurProcess.title3'), description: t('OurProcess.description3') },
    { title: t('OurProcess.title2'), description: t('OurProcess.description2') },
    { title: t('OurProcess.title4'), description: t('OurProcess.description4') },
    ];

    const handleNextClick = () => {
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
            setIsTransitioning(false);
        }, 500);
    };


    const handleIconClick = (stepIndex) => {
        if(stepIndex === currentStep){
            return ;
        }
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentStep(stepIndex);
            setIsTransitioning(false);
        }, 500);
    };

    const titleParts = steps[currentStep].title.split(' ');
    const description = steps[currentStep].description;
    const nextTitleFirstWord = steps[(currentStep + 1) % steps.length].title.split(' ')[0];
    const firstWord = titleParts.shift();
    const restOfTitle = titleParts.join(' ');

    return (
        <section id="our-process" className="our-process">
            <h1>{t('OurProcess.title')}</h1>
            <div className="external-process-icons">
                <img src={brainIcon} alt="Brain" 
                    className={currentStep === 0 ? 'active-icon' : ''} 
                    onClick={() => handleIconClick(0)} />
                <span>.<br/>.<br/>.<br/></span>
                <img src={codingIcon} alt="Coding" 
                    className={currentStep === 1 ? 'active-icon' : ''} 
                    onClick={() => handleIconClick(1)} />
                <span>.<br/>.<br/>.<br/></span>
                <img src={designIcon} alt="Design" 
                    className={currentStep === 2 ? 'active-icon' : ''} 
                    onClick={() => handleIconClick(2)} />
                <span>.<br/>.<br/>.<br/></span>
                <img src={pieChartIcon} alt="Pie Chart" 
                    className={currentStep === 3 ? 'active-icon' : ''} 
                    onClick={() => handleIconClick(3)} />
            </div>
            <div className="our-process-content">
                <div className='process-flex-container'>
                    <div className="process-icons">
                        <img src={brainIcon} alt="Brain" 
                            className={currentStep === 0 ? 'active-icon' : ''} 
                            onClick={() => handleIconClick(0)} />
                        <span>.<br/>.<br/>.<br/></span>
                        <img src={codingIcon} alt="Coding" 
                            className={currentStep === 1 ? 'active-icon' : ''} 
                            onClick={() => handleIconClick(1)} />
                        <span>.<br/>.<br/>.<br/></span>
                        <img src={designIcon} alt="Design" 
                            className={currentStep === 2 ? 'active-icon' : ''} 
                            onClick={() => handleIconClick(2)} />
                        <span>.<br/>.<br/>.<br/></span>
                        <img src={pieChartIcon} alt="Pie Chart" 
                            className={currentStep === 3 ? 'active-icon' : ''} 
                            onClick={() => handleIconClick(3)} />
                    </div>

                    <div className={`process-description ${isTransitioning ? 'transitioning' : ''}`} >
                        <h2>              
                            <span className="first-word">{firstWord}</span>
                            {' '}<span className="rest-of-words"> {restOfTitle}</span>
                        </h2>
                        <p>{description}</p>
                    </div>
                    
                    <div className="process-image">
                        <div className="process-image-inner">
                            <span className={`background-text ${isTransitioning ? 'transitioning' : ''}`}>{nextTitleFirstWord}</span>
                            <img src={Bulb} alt="Bulb" className='mainImage'/>
                            <img src={nextArrow} alt="Next" className="next-arrow" onClick={handleNextClick}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurProcess;